.task-list {
  .segment {
    height: calc(100vh - 153px);
    overflow-y: auto;
  }

  .column{
    min-width: 360px !important;
  }

  .content {
    padding: 22px 20px 10px 20px !important;
  }
}

.task-list-card {
  text-align: left;

  .header {
    color: #007cff !important;
  }


  .comments {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.74) !important;
  }

  .comments-time{
    font-size: 12px;
    color: #000 !important;
    font-weight: 900;
    margin-top: 6px;
    margin-bottom: 10px;
  }
}


.task-action-bar {
  margin: 0 0 15px 0;
  display: inline;

  button {
    margin: 0 10px 0 0 !important;


    &.delete-task {
      background: white;
      font-size: 14px;
      color: #d72f2f;

      &:hover {
        background: whitesmoke;
        color: #d72f2f;
        transform: scale(1.4);
      }
    }

    &.edit-task {
      background: white;
      font-size: 14px;
      color: #594a42;


      &:hover {
        background: whitesmoke;
        color: #594a42;
        transform: scale(1.4);
      }
    }
  }
}


.card-delete {
  font-size: 14px !important;
  color: red;
  float: right;
  cursor: pointer;
  &:hover{
    transform: scale(1.2);
  }
}

.edit-timer{
  margin-top: -12px !important;
  font-size: 12px !important;
  color: #ff7300 !important;
}

.add-card-btn{
  background: orange !important;
  color: white !important;
  &:hover{
    background: rgb(250, 196, 96) !important;
  }
}

.delete-card-btn{
  background: rgb(236, 35, 35) !important;
  color: white !important;
  &:hover{
    background: rgb(250, 102, 102) !important;
  }
}
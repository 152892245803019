.app-header{
  background: whitesmoke;
  margin-top: 0 !important;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.09);
  i{
    float: right;
    margin: -27px 30px !important;
    padding-right: 40px;
    background: #007bff;
    border-radius: 50%;
    width: 30px !important;
    height: 30px !important;
    color: white;
    padding: 5px;
    font-size: 12px !important;
    cursor: pointer;
  }
}